import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {
  translationEN,
  translationAr,
  assetsAr,
  assetsEn,
} from './locales/index';

if (!localStorage.getItem('i18nextLng'))
  localStorage.setItem('i18nextLng', 'ar');

const resources = {
  en: {
    translation: translationEN,
    assets: assetsEn,
  },
  ar: {
    translation: translationAr,
    assets: assetsAr,
  },
};

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: ['translation', 'assets'],
    resources,
    fallbackLng: ['ar', 'en'],
    defaultNS: 'home',
    load: 'languageOnly',
    debug: true,
    nterpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
// initialized and ready to go!
// i18next is already initialized, because the translation resources where passed via init function
export default i18next;
