import gql from 'graphql-tag';

const GET_CUSTOMERS = gql`
  query customers(
    $first: Int!
    $page: Int!
    $customerId: ID
    $suspected: Boolean
  ) {
    customers(
      first: $first
      page: $page
      suspected: $suspected
      id: $customerId
      orderBy: { field: "id", order: DESC }
    ) {
      paginatorInfo {
        total
      }
      data {
        id
        name
        email
        blocked
        phone
        suspected
        registered
        guest
      }
    }
  }
`;

export default GET_CUSTOMERS;
