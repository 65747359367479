import gql from 'graphql-tag';

const ADMIN_FORCE_CANCEL = gql`
  mutation adminForceCancelShipping($id: ID!, $deduction_amount: Float!) {
    adminForceCancelShipping(id: $id, deduction_amount: $deduction_amount) {
      status
      message
    }
  }
`;

export default ADMIN_FORCE_CANCEL;
