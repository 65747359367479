import gql from 'graphql-tag';

export const TOGGLE_NO_CANCEL =  gql`
  mutation toggleNoCancel($id: ID!) {
    toggleNoCancel(id: $id) {
      status
      message
    }
  }
`
export default TOGGLE_NO_CANCEL
