/** Component for listing all customers, filtering by phone
 * and adding new customers
 */
import React, { useState, useRef } from 'react';
import styled from '@emotion/styled/macro';
import { Table, Space, Button, Popconfirm, Modal,Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { SearchOutlined } from '@ant-design/icons';
import { GET_CUSTOMERS, GENERATE_CUSTOMER_CODE, GET_CUSTOMER_VERIFICATION_CODE } from 'services';
import { ListHeader, PrimaryButton, PrimaryTitle, Can } from 'components';
import SearchCusomters from 'lists/tickets/search-customers';
import { notify, ValidateUser } from '../../utilities';
import { useTranslation } from 'react-i18next';
import {
  toggleBlock,
  validCustomer,
} from '../../services/customers/update_customer';

const CashoutsListContainer = styled.div``;

const CustomersList = () => {
  ValidateUser();
  const history = useHistory();
  const searchInput = useRef(null);
  const [page, setPage] = useState(1);
  const [showCode, setShowCode] = useState({
    visible: false,
    code: undefined,
    expiryDate: undefined,
  });
  const [filteredValues, setFilteredValues] = useState({
    customerId: null,
  });
  const [t, i18n] = useTranslation("translation");
  const [getCustomerVerificationCode,{loading:gettingCode}] = useMutation(GET_CUSTOMER_VERIFICATION_CODE)
  const { data, loading, refetch } = useQuery(GET_CUSTOMERS, {
    variables: {
      page: 1,
      first: 6,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const [
    generateCode,
    { loading: generatingCode, refetch: refetchCode },
  ] = useMutation(GENERATE_CUSTOMER_CODE, { awaitRefetchQueries: true });
  const [
    makeCustomerValid,
    { loading: validating },
  ] = useMutation(validCustomer, { awaitRefetchQueries: true });
  const [toggleCustomerBlock, { loading: blocking }] = useMutation(
    toggleBlock,
    { awaitRefetchQueries: true }
  );

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const handelShowCode = (phone) => {
    getCustomerVerificationCode({
      variables: {
        phone: phone,
      },
    })
      .then((res) => {
        const {
          data: {
            getCustomerVerficationCode: { message, status, data },
          },
        } = res;
        if (status) {
          const { code, expiry_date } = data;
          setShowCode({ visible: !showCode.visible, code: code , expiryDate : expiry_date });
        } else {
          notify('error', message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <SearchCusomters
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            forwardedRef={searchInput}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, 'customerId')}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filteredValue: filteredValues.customerId,
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.focus(), 100);
        }
      },
    },
    {
      title: t('Is Registered'),
      key: 'registered',
      render: (customer) => (customer.registered ? t('true') : t('false')),
    },
    {
      title: t('Guest'),
      key: 'registered',
      render: (customer) => (customer.guest ? t('true') : t('false')),
    },
    {
      title: t('Suspected'),
      key: 'suspected',
      render: (customer) => (
        <>
          {customer.suspected ? t('true') : t('false')} /
          {customer.suspected ? (
            <Can
              perform="CREATE_CUSTOMER"
              yes={
                <Popconfirm
                  title={t(
                    'Are you sure you want to un suspect this customer?'
                  )}
                  okText={t('Yes')}
                  cancelText={t('No')}
                  onConfirm={() => valid(customer.id)}
                  onCancel={() => {}}
                >
                  <Button key={customer.id} loading={validating} type="primary">
                    Not Suspected
                  </Button>
                </Popconfirm>
              }
            />
          ) : (
            ''
          )}
        </>
      ),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: t('Blocked'),
      key: 'blocked',
      render: (customer) => (
        <>
          {customer.blocked ? t('true') : t('false')} /
          <Can
            perform="CREATE_CUSTOMER"
            yes={
              <Popconfirm
                title={
                  customer.blocked
                    ? t('Are you sure you want to un block this customer?')
                    : t('Are you sure you want to block this customer?')
                }
                okText={t('Yes')}
                cancelText={t('No')}
                onConfirm={() => ToggleCustomerBlock(customer.id)}
                onCancel={() => {}}
              >
                <Button
                  key={customer.id}
                  loading={blocking}
                  type={customer.blocked ? 'primary' : 'danger'}
                >
                  {customer.blocked ? 'UnBlock' : 'Block'}
                </Button>
              </Popconfirm>
            }
          />
        </>
      ),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Verification Code',
      dataIndex: ['phone'],
      render: (phone) => {
        return (
          <div>
            <Button type="default" onClick={() => handelShowCode(phone)}>
              {t('show_code')}
            </Button>
          </div>
        );
      },
      key: 'verification_code',
    },
    {
      title: 'Actions',
      render: (ticket) => {
        const currentTimestamp = new Date();
        const expiryTimestamp = new Date(
          ticket.verification_code && ticket.verification_code.expiry_time
        );
        return (
          <>
            <Space size="middle">
              <Button
                style={{ height: 18, padding: 0 }}
                type="link"
                disabled={currentTimestamp < expiryTimestamp}
                onClick={() =>
                  generateCode({
                    variables: {
                      id: ticket.id,
                    },
                    refetchQueries: () => [
                      {
                        query: GET_CUSTOMERS,
                        variables: {
                          page: page,
                          first: 6,
                        },
                      },
                    ],
                  })
                }
              >
                {t('Regenerate Code')}
              </Button>
            </Space>
          </>
        );
      },
    },
  ];
  //Translate Function For columns
  const ti81n = columns.map((ele) => {
    ele.title = t(ele.title);
  });

  const onChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setFilteredValues({
      ...filteredValues,
      customerId: filters.phone,
    });
    refetch({
      page: pagination.current,
      customerId: filters.phone || undefined,
    });
  };

  const valid = async (customer_id) => {
    await makeCustomerValid({
      variables: {
        customer_id,
      },
    });
    refetch();
  };
  const ToggleCustomerBlock = async (customer_id) => {
    await toggleCustomerBlock({
      variables: {
        customer_id,
      },
    });
    refetch();
  };
  const suspected = () => {
    refetch({
      page: 1,
      suspected: true,
    });
  };
  const all = () => {
    refetch({
      page: 1,
      suspected: undefined,
      customerId: undefined,
    });
  };
  return (
    <CashoutsListContainer>
      <ListHeader>
        <PrimaryTitle>{t('Customers')}</PrimaryTitle>
        <Can
          perform="CREATE_CUSTOMER"
          yes={
            <PrimaryButton onClick={() => history.push('/new-customer')}>
              {t('Add Customer')}
            </PrimaryButton>
          }
        />
      </ListHeader>
      <div className="table-operations" style={{ margin: '20px' }}>
        <Button type="danger" onClick={suspected}>
          Suspected
        </Button>
        <Button onClick={all}>All</Button>
      </div>
      <Spin spinning={gettingCode}>
        <Table
          bordered
          dataSource={data?.customers?.data}
          columns={columns}
          scroll={{ x: 400 }}
          loading={loading || generatingCode}
          onChange={onChange}
          rowKey="id"
          pagination={{
            total: data?.customers?.paginatorInfo?.total,
            pageSize: 6,
            showSizeChanger: false,
          }}
        />
      </Spin>
      <Modal
        visible={showCode.visible}
        footer=""
        width={'30%'}
        maskStyle={{ backgroundColor: '#00000024' }}
        onCancel={() => setShowCode({ visible: false, code: undefined })}
        style={{ margin: 'auto' }}
        title={t('Verification Code')}
      >
        <p>
          <span style={{ fontWeight: 'bold'}}>
            {t('Verification Code')}
          </span>{' '}
          : {showCode.code}
        </p>
        <p>
          <span style={{ fontWeight: 'bold' }}>
            {t('expierd_Code')}
          </span>{' '}
          : {showCode.expiryDate}
        </p>
      </Modal>
    </CashoutsListContainer>
  );
};

export default CustomersList;
