import gql from 'graphql-tag';

export const UPDATE_TRIP_NON_STOP = gql`
  mutation updateTripNonStop(
    $tripStart: Date!
    $tripEnd: Date!
    $template_id: ID!
    $location_id: ID!
  ) {
    updateTripNonStop(
      tripStart: $tripStart
      tripEnd: $tripEnd
      template_id: $template_id
      location_id: $location_id
    ) {
      message
      status
    }
  }
`;
export default UPDATE_TRIP_NON_STOP;
