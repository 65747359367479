import gql from 'graphql-tag';
const GET_CUSTOMER_VERIFICATION_CODE = gql`
  mutation getCustomerVerficationCode($phone: String!) {
    getCustomerVerficationCode(phone: $phone) {
      status
      message
      data {
        code
        expiry_date
      }
      __typename
    }
  }
`;
export default GET_CUSTOMER_VERIFICATION_CODE;
