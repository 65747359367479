import gql from 'graphql-tag';

export const TripTemplateList = gql`
  query tripTemplates($first: Int!, $page: Int, $ref_code: String) {
    tripTemplates(
      first: $first
      page: $page
      ref_code: $ref_code
      orderBy: { field: "id", order: DESC }
    ) {
      paginatorInfo {
        total
      }
      data {
        id
        ref_code
        access_level
        is_active
        from_date
        to_date
        edited_to
        locations {
          id
          name_en
          name_ar
        }
        routeLines {
          from_city {
            name_en
            code
          }
          to_city {
            name_en
            code
          }
        }
      }
    }
  }
`;

export default TripTemplateList;
