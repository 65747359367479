import gql from 'graphql-tag';

const GET_TICKETS = gql`
  query listtickets(
    $id: ID
    $first: Int!
    $status: [TicketStatus!]
    $code: String
    $customerId: ID
    $page: Int
    $tripNumber: String
    $tripStart: String
    $tripEnd: String
    $createdFrom: Date
    $createdTo: Date
    $fromLocation: ID
    $toLocation: ID
    $orderNumber: String
    $seatType: String
    $booked_by: Int
    $paid_by: Int
    $paid_at: Int
    $booked_at: Int
    $transaction_id: String
    $affiliate: String
    $payment_method: OrderPaymentMethods
    $agent_id: Int
  ) {
    tickets(
      id: $id
      first: $first
      status: $status
      code: $code
      affiliate: $affiliate
      customer_id: $customerId
      page: $page
      orderBy: { field: "id", order: DESC }
      trip_number: $tripNumber
      trip_start: $tripStart
      trip_end: $tripEnd
      from_location: $fromLocation
      payment_method: $payment_method
      to_location: $toLocation
      created_from: $createdFrom
      created_to: $createdTo
      order_number: $orderNumber
      seat_type: $seatType
      booked_by: $booked_by
      paid_by: $paid_by
      paid_at: $paid_at
      booked_at: $booked_at
      agent_id: $agent_id
      transaction_id: $transaction_id
      trashed: WITH
    ) {
      paginatorInfo {
        count
        total
        __typename
      }
      data {
        id
        price
        scanned
        no_cancel
        payment_method
        luggage_labels
        status
        print_count
        created_at
        from_date
        from_time
        seat_number
        open_rounded
        customer {
          id
          name
          phone
          __typename
        }
        order {
          id
          order_number
          affiliate
          agent {
            id
            user_name
          }
          booked_by {
            ... on User {
              id
              name
              __typename
            }
            ... on Customer {
              id
              name
              __typename
            }
            ... on Driver {
              id
              name
              __typename
            }
            __typename
          }

          booked_station {
            id
            name_en
            name_ar
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export default GET_TICKETS;
