import gql from 'graphql-tag';

const GET_TICKET_DATA = gql`
  query getticketdata($id: ID) {
    getticketdata(id: $id) {
      id
      uuid
      price
      scanned
      payment_method
      seller_type
      luggage_labels
      status
      print_count
      created_at
      from_date
      from_time
      open_rounded
      replaced_by
      no_cancel
      updated_to {
        id
        price
        __typename
      }
      old_ticket {
        id
        price
        __typename
      }
      new_tickets {
        id
        __typename
      }
      from_location {
        id
        name_en
        name_ar
        __typename
      }
      to_location {
        id
        name_en
        name_ar
        __typename
      }
      seat_number
      seat_type {
        id
        name_en
        name_ar
        __typename
      }
      customer {
        id
        name
        phone
        __typename
      }
      trip {
        id
        date
        time
        ref_code
        __typename
      }
      order {
        id
        order_number
        source
        booked_by {
          ... on User {
            id
            name
            __typename
          }
          ... on Customer {
            id
            name
            __typename
          }
          ... on Driver {
            id
            name
            __typename
          }
          __typename
        }
        paid_by {
          ... on User {
            id
            name
            __typename
          }
          ... on Driver {
            id
            name
            __typename
          }
          __typename
        }
        paid_station {
          id
          name_en
          __typename
        }
        booked_station {
          id
          name_en
          __typename
        }
        payment_method
        __typename
      }
      station_shift {
        id
        shift {
          id
          station {
            id
            name_en
            __typename
          }
          __typename
        }
        __typename
      }
      routeLine {
        execute_date {
          time
          date
          location_id
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export default GET_TICKET_DATA;
