/**List all the available tickets with an option to
 * filter ticket by number, user phone or ticket status.
 * This component contain action buttons for printing, viewing, editing
 * and cancelling ticket.
 * All tickets are exported in a single document here  */

import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import ExpandableTable from './ExpandableTable';
import {
  Table,
  Space,
  Button,
  Modal,
  Skeleton,
  Form,
  Input,
  Popconfirm,
  DatePicker,
  Select,
  Spin,
  Divider,
  Alert,
  Collapse,
} from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { SearchOutlined } from '@ant-design/icons';

import {
  FORCE_CANCEL_TICKET,
  GET_TICKETS,
  GET_TICKET_DATA,
  GET_CANCEL_DEDUCTION_AMOUNT,
  CANCEL_TICKET,
  EXPORT_TICKETS_REPORT,
  CheckEditTicket,
  me,
  GET_ACTIVE_LOCATIONS,
  CHECK_PRINT_TICKET_LABEL,
  GET_USERS,
  GET_USERLISTS,
  GET_PRINT_TICKET_FEES,
  EXPORT_TICKETS_REPORT_BACKGROUND,
  getCancelledTrip,
} from 'services';
import { notify, formatDate } from 'utilities';
import { ListHeader, PrimaryTitle, Can } from 'components';
import SearchCusomters from './search-customers';
import { ValidateUser } from '../../utilities/ValidateUser';
import { CurrentDateTime } from '../../utilities/current_date';
import { TimeToStartTrip } from '../../utilities/timeToStartTrip';
import { isMobile } from 'react-device-detect';
import SEND_DAILY_REPORT from '../../services/tickets/send_daily_report_query';
import ReplaceModal from '../../scenes/ticketing/replace-modal';
import { useTranslation } from 'react-i18next';
import GET_ALL_AGENTS from '../../services/agent/all-agents';
import { Agents, paymentMethods } from '../../config/paymentMethods';

const { Option, OptGroup } = Select;
const { Panel } = Collapse;
const ButtonWrapper = styled.div`
  button.ant-btn {
    height: 18px;
    padding: 0;
  }
`;
const { Item } = Form;
const TicketsListContainer = styled.div`
  .ant-form-item {
    margin: 10px !important;
  }
`;
const affiliates = ['waffarx', 'checkmybus'];
const TicketsList = () => {
  ValidateUser();
  const history = useHistory();
  const [t, i18n] = useTranslation('translation');
  const nameLang = i18n.language;
  const [selectedRows, setSelectedRows] = useState([]);
  const [form] = Form.useForm();
  const [forceCancelForm] = Form.useForm();
  const searchInput = useRef(null);
  const [visible, setVisible] = useState(false);
  const [forceVisible, setForceVisible] = useState(false);
  const [luggageModalVisible, setLuggageModalVisible] = useState(false);
  const [luggageNumber, setLuggageNumer] = useState(1);
  const [ticketId, setTicketId] = useState(0);
  const [page, setPage] = useState(1);
  const { data: agent_list } = useQuery(GET_ALL_AGENTS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const [filteredValues, setFilteredValues] = useState({
    id: undefined,
    created_at: undefined,
    fromLocation: undefined,
    toLocation: undefined,
    orderNumber: undefined,
    status: undefined,
    code: undefined,
    tripNumber: undefined,
    tripDate: undefined,
    transaction_id: undefined,
    payment_method: undefined,
    agent_id: undefined,
    affiliate: undefined,
    customerId: undefined,
  });
  const [ticketIdParams, setTicketParams] = useState(null);
  const [checkPrintModalVisible, setCheckPrintModalVisible] = useState(false);
  const [ticketsToPrint, setTicketsToPrint] = useState([]);
  const [showReplace, setShowReplace] = useState(false);
  const [ticketInfo, setTicketInfo] = useState(null);
  const rejectCancelMethods = ['Wallet', 'Card', 'Link'];

  const { data, loading, refetch } = useQuery(GET_TICKETS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      first: 10,
    },
  });

  const [
    getDeductionAmount,
    { data: deductionAmountData, loading: fetchingDeductionAmount },
  ] = useLazyQuery(GET_CANCEL_DEDUCTION_AMOUNT, {
    fetchPolicy: 'network-only',
  });
  const [
    SendDailyReport,
    { data: dailyReportResponse, loading: sendingDataReport },
  ] = useLazyQuery(SEND_DAILY_REPORT, {
    fetchPolicy: 'network-only',
  });

  const [getEditTicketDeductionAmount] = useLazyQuery(CheckEditTicket, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res.getEditTicketDeductionAmount.status && ticketIdParams) {
        history.push(`/ticketing?ticketId=${ticketIdParams}`);
      } else {
        notify(
          'error',
          nameLang === 'en'
            ? res.getEditTicketDeductionAmount.message
            : res.getEditTicketDeductionAmount.message_ar
        );
      }
    },
  });
  const [getUpgradeSeat] = useLazyQuery(CheckEditTicket, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res.getEditTicketDeductionAmount.status && ticketIdParams) {
        history.push(`/ticketing?ticketId=${ticketIdParams}&upgrade=1`);
      } else {
        notify(
          'error',
          nameLang === 'en'
            ? res.getEditTicketDeductionAmount.message
            : res.getEditTicketDeductionAmount.message_ar
        );
      }
    },
  });
  const [getTicketInfo, { loading: loadingData }] = useLazyQuery(
    GET_TICKET_DATA,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        if (res?.getticketdata) {
          setShowReplace(true);
          setTicketInfo(res.getticketdata);
        }
      },
    }
  );

  const [cancelTiket, { loading: cancelling }] = useMutation(CANCEL_TICKET);
  const [forceCancelTicket, { loading: forceCancelling }] = useMutation(
    FORCE_CANCEL_TICKET
  );
  const [checkPrintTicketLuggage] = useLazyQuery(CHECK_PRINT_TICKET_LABEL, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (
        res.checkPrintTicketLuggage.status &&
        res.checkPrintTicketLuggage.ticketIds
      ) {
        setLuggageModalVisible(false);
        window.open(
          '/tickets/view-luggage/' +
            res.checkPrintTicketLuggage.ticketIds +
            '?lugNum=' +
            luggageNumber
        );
      } else {
        notify('error', res.checkPrintTicketLuggage.message);
      }
    },
    onError: (error) => {
      if (error) {
        if (error['graphQLErrors'][0]?.extensions) {
          const {
            extensions: { validation },
            message,
          } = error['graphQLErrors'][0];
          if (validation) {
            for (let error in validation) {
              notify('error', validation[error][0]);
            }
          }
          notify('error', message);
          setLuggageModalVisible(false);
        }
      }
    },
  });
  const [getPrintFees, { data: printFees }] = useLazyQuery(
    GET_PRINT_TICKET_FEES,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        if (res.checkPrintTicketFees.printFees != 0) {
          setCheckPrintModalVisible(true);
        } else {
          window.open('/tickets/view/' + ticketsToPrint.join('&'));
        }
      },
      onError: (err) => {
        if (err['graphQLErrors'][0]?.extensions) {
          const {
            extensions: { validation },
            message,
          } = err['graphQLErrors'][0];
          if (validation) {
            for (let error in validation) {
              notify('error', validation[error][0]);
            }
            return;
          }
          notify('error', message);
        }
      },
    }
  );
  const [exportReport, { loading: exporting }] = useMutation(
    EXPORT_TICKETS_REPORT,
    {
      variables: {
        fromLocation: filteredValues.fromLocation || '',
        toLocation: filteredValues.toLocation || '',
        id: filteredValues.id || '',
        createdFrom: filteredValues.createdFrom || '',
        createdTo: filteredValues.createdTo || '',
        status: filteredValues.status || '',
        tripStart: filteredValues.tripStart || '',
        tripEnd: filteredValues.tripEnd || '',
        code: filteredValues.code || '',
        tripNumber: filteredValues.tripNumber || '',
        orderNumber: filteredValues.orderNumber || '',
        affiliate: filteredValues.affiliate || '',
        seatType: filteredValues.seatType || '',
        agent_id: filteredValues.agent_id || '',
        payment_method: filteredValues.payment_method || '',
        customerId: filteredValues.customerId || '',
      },
    }
  );
  const [
    exportReportBackground,
    { loading: exportingBackground },
  ] = useMutation(EXPORT_TICKETS_REPORT_BACKGROUND, {
    variables: {
      fromLocation: filteredValues.fromLocation || '',
      toLocation: filteredValues.toLocation || '',
      id: filteredValues.id || '',
      createdFrom: filteredValues.createdFrom || '',
      createdTo: filteredValues.createdTo || '',
      status: filteredValues.status || '',
      tripStart: filteredValues.tripStart || '',
      tripEnd: filteredValues.tripEnd || '',
      code: filteredValues.code || '',
      tripNumber: filteredValues.tripNumber || '',
      orderNumber: filteredValues.orderNumber || '',
      affiliate: filteredValues.affiliate || '',
      seatType: filteredValues.seatType || '',
      agent_id: filteredValues.agent_id || '',
      payment_method: filteredValues.payment_method || '',
    },
  });

  const ExportingBackGround = () => {
    exportReportBackground()
      .then((response) => {
        const {
          data: {
            exportTicketsBackground: { message, status },
          },
        } = response;
        const notificationType = status ? 'success' : 'error';
        notify(notificationType, message);
      })
      .catch((err) => {
        if (err['graphQLErrors'][0]?.extensions) {
          const {
            extensions: { validation },
            message,
          } = err['graphQLErrors'][0];
          if (validation) {
            for (let error in validation) {
              notify('error', validation[error][0]);
            }
            return;
          }
          notify('error', message);
        }
      });
  };
  const handleOk = () => {
    cancelTiket({
      variables: {
        id: ticketId,
        deduction_amount:
          deductionAmountData?.getCancelTicketDeductionAmount?.data
            ?.deduction_amount,
      },
    })
      .then((response) => {
        const {
          data: {
            adminCancelTicket: { message, status },
          },
        } = response;
        const notificationType = status ? 'success' : 'error';
        refetch({ page: page });
        notify(notificationType, message);
        setTicketId(0);
        setVisible(false);
      })
      .catch((err) => {
        if (err['graphQLErrors'][0]?.extensions) {
          const {
            extensions: { validation },
            message,
          } = err['graphQLErrors'][0];
          if (validation) {
            for (let error in validation) {
              notify('error', validation[error][0]);
            }
            return;
          }
          notify('error', message);
        }
      });
  };
  const ForceCancel = (values) => {
    const { deduction_amount } = values;
    forceCancelTicket({
      variables: {
        id: ticketId,
        deduction_amount: deduction_amount,
      },
    })
      .then((response) => {
        const {
          data: {
            adminForceCancelTicket: { message, status },
          },
        } = response;
        const notificationType = status ? 'success' : 'error';
        refetch({ page: page });
        notify(notificationType, message);
        forceCancelForm.resetFields();
        setTicketId(0);
        setForceVisible(false);
      })
      .catch((err) => {
        if (err['graphQLErrors'][0]?.extensions) {
          const {
            extensions: { validation },
            message,
          } = err['graphQLErrors'][0];
          if (validation) {
            for (let error in validation) {
              notify('error', validation[error][0]);
            }
            return;
          }
          notify('error', message);
        }
      });
  };

  const handleSendingDailyReport = () => {
    SendDailyReport();
  };

  const handleCancel = () => {
    setTicketId(0);
    setVisible(false);
    setLuggageModalVisible(false);
    setCheckPrintModalVisible(false);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const disablePrintTicket = (ticket) => {
    let ticketStatus = ticket.status;
    let canPrint = false;
    let executionDateTime = ticket?.from_date + ' ' + ticket?.from_time;

    if (
      ticketStatus === 'Cancelled' ||
      ticketStatus === 'Expired' ||
      ticketStatus === 'Pending' ||
      ticketStatus === 'Modified' ||
      ticket.scanned === 'checkedIn' ||
      executionDateTime < CurrentDateTime()
    ) {
      canPrint = true;
    }
    return canPrint;
  };
  const getTicketData = (ticketId) => {
    getTicketInfo({
      variables: {
        id: ticketId,
      },
    });
  };
  const disabledPrintLuggage = (luggage_counter, ticketStatus) => {
    if (
      luggage_counter >= 4 ||
      ticketStatus === 'Cancelled' ||
      ticketStatus === 'Expired' ||
      ticketStatus === 'Pending' ||
      ticketStatus === 'Modified'
    )
      return true;
    else return false;
  };
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
    },
    {
      title: 'Name',
      dataIndex: ['customer', 'name'],
    },
    {
      title: 'Phone',
      dataIndex: ['customer', 'phone'],
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <SearchCusomters
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            forwardedRef={searchInput}
          />
          <Space>
            <Button
              type="primary"
              onClick={(e) => {
                handleSearch(selectedKeys, confirm, 'customerId');
                setFilteredValues({
                  ...filteredValues,
                  customerId: selectedKeys,
                });
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filteredValue: filteredValues.customerId,
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.focus(), 100);
        }
      },
    },
    {
      title: 'Order Number',
      dataIndex: ['order', 'order_number'],
    },

    {
      title: 'Ticket Price',
      key: 'price',
      render: (ticket) => `${ticket.price} L.E`,
    },
    {
      title: 'Payment Method',
      key: 'payment_method',
      render: (ticket) => {
        if (
          ticket?.order?.booked_by?.name &&
          Agents.includes(ticket?.order?.booked_by?.name)
        ) {
          return ticket?.order?.booked_by?.name;
        } else if (ticket.payment_method) {
          let paymentMethod = ticket.payment_method
            .match(/[A-Z][a-z]+|[0-9]+/g)
            .join(' ');

          return t(paymentMethod);
        }
      },
    },
    {
      title: 'Ticket Status',
      // dataIndex: 'status',
      render: (record) => t(record.status),
      filters: [
        { text: t('Paid'), value: 'Paid' },
        { text: t('Pending'), value: 'Pending' },
        { text: t('Cancelled'), value: 'Cancelled' },
        { text: t('Expired'), value: 'Expired' },
      ],
      filteredValue: filteredValues.status,
    },
    {
      title: 'Booked at',
      key: 'payment_method',
      render: (ticket) => {
        if (ticket?.payment_method == 'DriverCash') return t('Bus');
        if (
          ticket.order?.booked_station?.name_en ||
          ticket.order?.booked_station?.name_ar
        )
          return nameLang === 'en'
            ? ticket.order?.booked_station?.name_en
            : ticket.order?.booked_station?.name_ar;
        else return t('No Station');
      },
    },
    {
      title: 'Paid At',
      key: 'payment_method',
      render: (ticket) => {
        if (ticket?.payment_method == 'DriverCash') return 'Bus';
        return ticket.order?.paid_station?.name_en;
      },
    },
    {
      title: 'Paid By',
      render: (ticket) => {
        if (ticket.order) return ticket?.order?.paid_by?.name;
      },
    },
    {
      title: 'Booked By',
      render: (ticket) => {
        if (ticket.order)
          return (
            t(ticket.order?.agent?.user_name) ||
            t(ticket?.order?.booked_by?.name)
          );
      },
    },
    {
      title: 'Scanned',

      render: (ticket) => {
        let trip_date_time = ticket?.from_date + ' ' + ticket?.from_time;
        let dif = TimeToStartTrip(trip_date_time, CurrentDateTime());

        if (ticket.open_rounded && !ticket.scanned) return t('T Not Started');
        else if (dif > 60) {
          return t('T Not Started');
        } else {
          return t(ticket.scanned);
        }
      },
      // dataIndex: 'scanned',
    },
    {
      title: 'Print Count',
      dataIndex: 'print_count',
    },
    {
      title: 'Created At',
      key: 'created_at',
      render: (ticket) => formatDate(ticket.created_at),
    },
    {
      title: 'Referral',
      dataIndex: ['order', 'affiliate'],
    },
    {
      title: 'Actions',
      render: (ticket) => {
        const {
          id,
          from_date,
          from_time,
          status,
          luggage_labels,
          open_rounded,
        } = ticket;
        const todayDate = new Date();
        const ticketDate = new Date([from_date, from_time].join(' '));
        const CancelTicketButton = (
          <ButtonWrapper>
            {!rejectCancelMethods.includes(ticket.payment_method) && (
              <Button
                type="link"
                danger
                onClick={() => {
                  if (ticket.no_cancel) {
                    notify('error', t('non_refundable'));
                  } else {
                    setVisible(true);
                    setTicketId(id);
                    getDeductionAmount({
                      variables: {
                        id,
                      },
                    });
                  }
                }}
              >
                {t('Cancel')}
              </Button>
            )}
          </ButtonWrapper>
        );
        const ReplaceOpenRounded = (
          <Button
            type="primary"
            onClick={() => {
              getTicketData(ticket.id);
            }}
          >
            {t('Replace')}
          </Button>
        );

        const ForceCancelTicketButton = (
          <ButtonWrapper>
            <Button
              type="link"
              danger
              onClick={() => {
                setForceVisible(true);
                setTicketId(id);
              }}
            >
              {t('Force Cancel')}
            </Button>
          </ButtonWrapper>
        );
        return (
          <Space size="middle">
            <Button
              disabled={disablePrintTicket(ticket)}
              type="primary"
              onClick={() => {
                setTicketsToPrint([ticket.id]);
                getPrintFees({
                  variables: {
                    id: [ticket.id],
                  },
                });
              }}
            >
              {t('Print')}
            </Button>
            <Button
              onClick={() => {
                setLuggageModalVisible(true);
                setTicketId(id);
              }}
              type="link"
              disabled={disabledPrintLuggage(luggage_labels, status)}
            >
              {t('Print Label')}
            </Button>
            {
              <Can
                perform="Modify_Ticket"
                yes={
                  <Button
                    type="link"
                    onClick={() => {
                      setTicketParams(id);
                      getEditTicketDeductionAmount({
                        variables: {
                          ticketId: id,
                        },
                      });
                    }}
                  >
                    {t('Edit')}
                  </Button>
                }
              />
            }

            <Can
              perform="Upgrade_Seat"
              yes={
                <Button
                  type="link"
                  onClick={() => {
                    setTicketParams(id);
                    getUpgradeSeat({
                      variables: {
                        ticketId: id,
                        upgrade: true,
                      },
                    });
                  }}
                >
                  {t('Upgrade Seat')}
                </Button>
              }
            />

            {ticket?.payment_method && (
              <Link to={`/ticket/${id}`}>{t('View')}</Link>
            )}

            {status === 'Cancelled' ||
            status === 'Modified' ||
            todayDate > ticketDate ? null : (
              <Can perform="CANCEL_TICKET" yes={CancelTicketButton} />
            )}
            <Can perform="FORCE_CANCEL_TICKET" yes={ForceCancelTicketButton} />
            {status == 'Paid' && open_rounded && !ticket?.seat_number ? (
              <Can perform="Modify_Ticket_OPEN" yes={ReplaceOpenRounded} />
            ) : null}
          </Space>
        );
      },
    },
  ];
  //Translate Function For columns
  const ti81n = columns.map((ele) => {
    ele.title = t(ele.title);
  });

  const statusOptions = [
    `All Tickets`,
    'Cancelled',
    'Pending',
    'Paid',
    'Expired',
    'Modified',
    'Replaced',
  ];
  const seatClasses = [
    'Comfort',
    'First',
    'Business',
    'Business Prime',
    'First Prime',
  ];
  const handleStatusChange = (value) => {
    if (value === 'All Tickets')
      refetch({ status: undefined, page: 1, first: 10 });
    else refetch({ status: value, page: 1, first: 10 });
  };
  const rowSelection = {
    getCheckboxProps: (record) => {
      return {
        disabled:
          disablePrintTicket(record.luggage_labels, record.status) ||
          disablePrintTicket(record),
      };
    },
    hideSelectAll: true,
    onSelect: (selectedRowKey, checkSelect) => {
      if (checkSelect === true) {
        setSelectedRows([...selectedRows, selectedRowKey.id]);
      } else {
        selectedRows.splice(selectedRows.indexOf(selectedRowKey.id), 1);
      }
    },
  };

  const onChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    refetch({
      ...filteredValues,
      page: pagination.current,
      customerId: filters['customer.phone'] || undefined,
    });
  };
  const onFinish = (values) => {
    setPage(1);

    const {
      ticketId,
      tripNumber,
      tripDate,
      ticketNumber,
      fromLocation,
      toLocation,
      createdAt,
      orderNumber,
      seatType,
      ticketStatus,
      booked_by,
      paid_by,
      paid_at,
      booked_at,
      agent_id,
      payment_method,
      transaction_id,
      affiliate,
    } = values;
    const FORMAT_YYYY_MM_DD = 'YYYY-MM-DD';
    setFilteredValues({
      id: ticketId || undefined,
      transaction_id: transaction_id || undefined,
      createdFrom: createdAt
        ? createdAt[0].toISOString().split('T')[0]
        : undefined,
      createdTo: createdAt
        ? createdAt[1].toISOString().split('T')[0]
        : undefined,
      fromLocation: fromLocation || undefined,
      toLocation: toLocation || undefined,
      code: ticketNumber || undefined,
      tripNumber: tripNumber || undefined,
      tripStart: tripDate ? tripDate[0].format(FORMAT_YYYY_MM_DD) : undefined,
      tripEnd: tripDate ? tripDate[1].format(FORMAT_YYYY_MM_DD) : undefined,
      orderNumber: orderNumber ? orderNumber : undefined,
      affiliate:
        affiliate && affiliate != 'All Referral' ? affiliate : undefined,
      seatType: seatType ? seatType : undefined,
      status:
        ticketStatus && ticketStatus != 'All Tickets'
          ? ticketStatus
          : undefined,
      payment_method:
        payment_method && agent_id != 'All Payments'
          ? payment_method
          : undefined,
      booked_by: booked_by || undefined,
      paid_by: paid_by || undefined,
      paid_at: paid_at || undefined,
      booked_at: booked_at || undefined,
      agent_id: agent_id && agent_id != 'All Agents' ? agent_id : undefined,
    });
    refetch({
      id: ticketId || undefined,
      transaction_id: transaction_id || undefined,
      createdFrom: createdAt
        ? createdAt[0].toISOString().split('T')[0]
        : undefined,
      createdTo: createdAt
        ? createdAt[1].toISOString().split('T')[0]
        : undefined,
      fromLocation: fromLocation ? fromLocation : undefined,
      toLocation: toLocation || undefined,
      code: ticketNumber || undefined,
      tripNumber: tripNumber || undefined,
      tripStart: tripDate ? tripDate[0].format(FORMAT_YYYY_MM_DD) : undefined,
      tripEnd: tripDate ? tripDate[1].format(FORMAT_YYYY_MM_DD) : undefined,
      orderNumber: orderNumber ? orderNumber : undefined,
      seatType: seatType ? seatType : undefined,
      status:
        ticketStatus && ticketStatus != 'All Tickets'
          ? ticketStatus
          : undefined,
      affiliate:
        affiliate && affiliate != 'All Referral' ? affiliate : undefined,
      agent_id: agent_id && agent_id != 'All Agents' ? agent_id : undefined,
      payment_method:
        payment_method && agent_id != 'All Payments'
          ? payment_method
          : undefined,
      booked_by: booked_by || undefined,
      paid_by: paid_by || undefined,
      paid_at: paid_at || undefined,
      booked_at: booked_at || undefined,
      page: 1,
      first: 10,
    });
  };
  const resetFilters = () => {
    form.resetFields();
    setFilteredValues({
      id: undefined,
      createdFrom: undefined,
      createdTo: undefined,
      fromLocation: undefined,
      toLocation: undefined,
      code: undefined,
      tripNumber: undefined,
      tripStart: undefined,
      tripEnd: undefined,
      orderNumber: undefined,
      seatType: undefined,
      status: undefined,
      customerId: undefined,
      booked_by: undefined,
      paid_by: undefined,
      paid_at: undefined,
      booked_at: undefined,
      transaction_id: undefined,
      affiliate: undefined,
    });
    refetch({
      page: page,
      status: undefined,
      code: undefined,
      customerId: undefined,
      createdFrom: undefined,
      createdTo: undefined,
      tripNumber: undefined,
      id: undefined,
      tripStart: undefined,
      tripEnd: undefined,
      fromLocation: undefined,
      toLocation: undefined,
      orderNumber: undefined,
      affiliate: undefined,
      seatType: undefined,
      booked_by: undefined,
      paid_by: undefined,
      paid_at: undefined,
      booked_at: undefined,
      transaction_id: undefined,
    });
  };
  const handleExport = () => {
    exportReport()
      .then((res) => {
        const {
          data: {
            exportTickets: {
              message,
              status,
              data: { downloadable_link },
            },
          },
        } = res;
        var a = document.createElement('a');
        a.href = downloadable_link;
        document.body.appendChild(a);
        a.click();
        a.remove();
        const notificationType = status ? 'success' : 'error';
        notify(notificationType, message);
      })
      .catch((err) => {
        const {
          extensions: { validation },
          message,
        } = err['graphQLErrors'][0];

        if (validation) {
          for (let error in validation) {
            notify('error', validation[error][0]);
          }
          return;
        }
        notify('error', message);
      });
  };
  const handlePrintBulkTickets = () => {
    if (selectedRows.length > 0) {
      let tickets = [...new Set(selectedRows.flat())].join('&');
      window.open('/tickets/view/' + tickets);
    }
  };

  // const displayticket = (ticket) => {
  //   let tid = ticket[0]?.id;
  //   const res = getTicketInfo({
  //     variables: {
  //       id: '195123',
  //     },
  //   });
  //   return (
  //     <Table
  //       bordered
  //       columns={expandedColumns}
  //       dataSource={ticket}
  //       pagination={false}
  //       rowKey={(record) => record.id}
  //     />
  //   );
  // };
  const handleBulkPrintTicketsLuggage = () => {
    if (selectedRows.length > 0) {
      let tickets = [...new Set(selectedRows.flat())].join('&');
      checkPrintTicketLuggage({
        variables: {
          ticketId: tickets,
          luggageNumber: 1,
        },
      });
    }
  };
  const { data: locationsData, loading: loadingLocations } = useQuery(
    GET_ACTIVE_LOCATIONS
  );
  const { data: users, loading: usersLoading } = useQuery(GET_USERLISTS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      first: 100,
      page: 1,
    },
  });
  const { activeLocations } = locationsData || { activeLocations: [] };
  const availableLocations = activeLocations.reduce((result, location) => {
    const cityName = location.city.name_en;
    const cityId = location.city.id;

    if (!Object.keys(result).includes(cityName)) {
      result[cityName] = [{ ...location, city_id: cityId }];
    } else {
      result[cityName].push({ ...location, city_id: cityId });
    }
    return result;
  }, {});
  const { RangePicker } = DatePicker;
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  return (
    <TicketsListContainer>
      <ListHeader>
        <PrimaryTitle>{t('Tickets')}</PrimaryTitle>
        {dailyReportResponse && (
          <Alert
            message={
              'Daily response for to day -' +
              dailyReportResponse?.sendDailyMailReport?.message
            }
          ></Alert>
        )}
        <Space
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirectiom: 'row',
            flexWrap: 'wrap',
          }}
        >
          <Can
            perform="EXPORT_TICKET_REPORT"
            yes={
              <Button
                danger
                type="primary"
                loading={exporting}
                onClick={() => handleExport()}
              >
                {t('Export Report')}
              </Button>
            }
          />
          <Can
            perform="EXPORT_TICKETS_REPORT_BACKGROUND"
            yes={
              <Button
                danger
                type="primary"
                loading={exportingBackground}
                onClick={() => ExportingBackGround()}
              >
                {t('Export Report Background')}
              </Button>
            }
          />
          <Can
            perform="SEND_DAILY_REPORT"
            yes={
              <Button
                type="primary"
                loading={sendingDataReport}
                onClick={() => handleSendingDailyReport()}
              >
                {t('Send Daily Report')}
              </Button>
            }
          />
          <Can
            perform="PRINT_TICKETS"
            yes={
              <Button
                type="primary"
                onClick={() => {
                  if (selectedRows.length > 0) {
                    setTicketsToPrint([...new Set(selectedRows.flat())]);
                    getPrintFees({
                      variables: {
                        id: [...new Set(selectedRows.flat())],
                      },
                    });
                  }
                }}
              >
                {t('Print Tickets')}
              </Button>
            }
          />
          <Can
            perform="PRINT_TICKETS"
            yes={
              <Button
                type="primary"
                onClick={() => handleBulkPrintTicketsLuggage()}
              >
                {t('Print Luggage Labels')}
              </Button>
            }
          />
        </Space>
      </ListHeader>
      <Collapse style={{ margin: '20px' }}>
        <Panel header={t('Show Filter Form')} key="1">
          <div>
            <Form
              {...formItemLayout}
              form={form}
              onFinish={onFinish}
              layout={'horizontal'}
            >
              <Item name="ticketId" label={t('Ticket Id')}>
                <Input placeholder={t('Enter Ticket Id')} />
              </Item>
              <Item name="transaction_id" label={t('Transaction Id')}>
                <Input placeholder={t('Transaction Id')} />
              </Item>

              <Item name="payment_method" label={t('Payment method')}>
                <Select
                  size="large"
                  defaultValue={t('All Payments')}
                  placeholder={t('payment method')}
                >
                  {paymentMethods.map((method, index) => (
                    <Option key={method}>{t(method)}</Option>
                  ))}
                </Select>
              </Item>
              <Item name="affiliate" label={t('Referral')}>
                <Select
                  size="large"
                  defaultValue={t('All Referral')}
                  placeholder={t('Referral')}
                >
                  {affiliates.map((affiliate, index) => (
                    <Option key={affiliate}>{t(affiliate)}</Option>
                  ))}
                </Select>
              </Item>
              <Item name="booked_by" label={t('Booked by')}>
                <Select
                  size="large"
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  placeholder={t('Select Booked user')}
                >
                  {users &&
                    users.users &&
                    users.users.data.map((user, idx) => (
                      <Option key={idx} value={user.id}>
                        {user.name}
                      </Option>
                    ))}
                </Select>
              </Item>
              <Item name="paid_by" label={t('Paid by')}>
                <Select
                  size="large"
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  placeholder={t('Select Paid by')}
                >
                  {users &&
                    users.users &&
                    users.users.data.map((user, idx) => (
                      <Option key={idx} value={user.id}>
                        {user.name}
                      </Option>
                    ))}
                </Select>
              </Item>

              <Item name="ticketStatus" label={t('Status')}>
                <Select defaultValue="All Tickets" size="large">
                  {statusOptions.map((option, idx) => (
                    <Option key={idx} value={option}>
                      {t(option)}
                    </Option>
                  ))}
                </Select>
              </Item>

              <Item name="agent_id" label={t('Agent')}>
                <Select size="large" defaultValue={t('All Agents')}>
                  {agent_list &&
                    agent_list.all_agents.map((agent, idx) => (
                      <Option key={idx} value={agent.id}>
                        {t(agent.user_name)}
                      </Option>
                    ))}
                </Select>
              </Item>
              <Item name="orderNumber" label={t('Order Number')}>
                <Input placeholder={t('Enter Order Number')} />
              </Item>

              <Item name="tripNumber" label={t('Trip Number')}>
                <Input placeholder={t('Enter Trip Number')} />
              </Item>
              <Item name="tripDate" label={t('Trip Date')}>
                <RangePicker
                  size="large"
                  placeholder={[t('Start date'), t('End date')]}
                />
              </Item>
              <Item name="createdAt" label={t('Created at')}>
                <RangePicker
                  size="large"
                  placeholder={[t('Start date'), t('End date')]}
                />
              </Item>
              <Item name="fromLocation" label={t('From Location')}>
                <Select
                  placeholder={t('Select Assigned Station')}
                  size="large"
                  showSearch
                  optionFilterProp="children"
                  allowClear
                >
                  {loadingLocations ? (
                    <Option
                      value={null}
                      disabled
                      style={{ textAlign: 'center' }}
                    >
                      <Spin tip="Loading Locations..." />
                    </Option>
                  ) : (
                    Object.keys(availableLocations).map(
                      (cityName, cityIndex) => (
                        <OptGroup key={`city_${cityIndex}`} label={cityName}>
                          {availableLocations[cityName].map(
                            (location, locationIndex) => (
                              <Option
                                key={`location_${cityIndex}_${locationIndex}`}
                                value={location.id}
                              >
                                {nameLang === 'en'
                                  ? location.name_en
                                  : location.name_ar}
                              </Option>
                            )
                          )}
                        </OptGroup>
                      )
                    )
                  )}
                </Select>
              </Item>

              <Item name="toLocation" label={t('To Location')}>
                <Select
                  placeholder={t('Select Assigned Station')}
                  size="large"
                  showSearch
                  optionFilterProp="children"
                  allowClear
                >
                  {loadingLocations ? (
                    <Option
                      value={null}
                      disabled
                      style={{ textAlign: 'center' }}
                    >
                      <Spin tip="Loading Locations..." />
                    </Option>
                  ) : (
                    Object.keys(availableLocations).map(
                      (cityName, cityIndex) => (
                        <OptGroup key={`city_${cityIndex}`} label={cityName}>
                          {availableLocations[cityName].map(
                            (location, locationIndex) => (
                              <Option
                                key={`location_${cityIndex}_${locationIndex}`}
                                value={location.id}
                              >
                                {nameLang === 'en'
                                  ? location.name_en
                                  : location.name_ar}
                              </Option>
                            )
                          )}
                        </OptGroup>
                      )
                    )
                  )}
                </Select>
              </Item>
              <Item name="paid_at" label={t('Paid Station')}>
                <Select
                  placeholder={t('Select paid Station')}
                  size="large"
                  showSearch
                  optionFilterProp="children"
                  allowClear
                >
                  {loadingLocations ? (
                    <Option
                      value={null}
                      disabled
                      style={{ textAlign: 'center' }}
                    >
                      <Spin tip="Loading Locations..." />
                    </Option>
                  ) : (
                    Object.keys(availableLocations).map(
                      (cityName, cityIndex) => (
                        <OptGroup key={`city_${cityIndex}`} label={cityName}>
                          {availableLocations[cityName].map(
                            (location, locationIndex) => (
                              <Option
                                key={`location_${cityIndex}_${locationIndex}`}
                                value={location.id}
                              >
                                {nameLang === 'en'
                                  ? location.name_en
                                  : location.name_ar}
                              </Option>
                            )
                          )}
                        </OptGroup>
                      )
                    )
                  )}
                </Select>
              </Item>
              <Item name="booked_at" label={t('Booked Station')}>
                <Select
                  placeholder={t('Select Booked Station')}
                  size="large"
                  showSearch
                  optionFilterProp="children"
                  allowClear
                >
                  {loadingLocations ? (
                    <Option
                      value={null}
                      disabled
                      style={{ textAlign: 'center' }}
                    >
                      <Spin tip="Loading Locations..." />
                    </Option>
                  ) : (
                    Object.keys(availableLocations).map(
                      (cityName, cityIndex) => (
                        <OptGroup key={`city_${cityIndex}`} label={cityName}>
                          {availableLocations[cityName].map(
                            (location, locationIndex) => (
                              <Option
                                key={`location_${cityIndex}_${locationIndex}`}
                                value={location.id}
                              >
                                {nameLang === 'en'
                                  ? location.name_en
                                  : location.name_ar}
                              </Option>
                            )
                          )}
                        </OptGroup>
                      )
                    )
                  )}
                </Select>
              </Item>

              <Item>
                <Button type="primary" htmlType="submit">
                  {t('Search')}
                </Button>
              </Item>
              <Item>
                <Popconfirm
                  title={t('Are you sure you want to reset all filters?')}
                  okText={t('Yes')}
                  cancelText={t('No')}
                  onConfirm={() => resetFilters()}
                  onCancel={() => {}}
                >
                  <Button htmlType="button" type="primary" danger>
                    {t('Reset All Filters')}
                  </Button>
                </Popconfirm>
              </Item>
            </Form>
          </div>
        </Panel>
      </Collapse>
      <Table
        bordered
        dataSource={data && data.tickets.data}
        columns={columns}
        loading={loading || loadingData}
        onChange={onChange}
        expandable={{
          expandedRowRender: (record) => (
            <ExpandableTable id={[record][0].id} />
          ),
        }}
        rowSelection={{
          ...rowSelection,
        }}
        rowKey="id"
        pagination={{
          total: data && data.tickets.paginatorInfo.total + 1,
          pageSize: 10,
          current: page,
          showSizeChanger: false,
        }}
        scroll={{ x: 400 }}
      />

      <Modal
        title={
          <Space size={'middle'}>
            <ExclamationCircleOutlined
              style={{ fontSize: 22, color: '#FFCC00' }}
            />
            {t('Cancel Ticket')}
          </Space>
        }
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            {t('Return')}
          </Button>,
          <Button
            danger
            key="submit"
            type="primary"
            onClick={handleOk}
            loading={cancelling}
          >
            {t('Confirm')}
          </Button>,
        ]}
      >
        <Skeleton
          active
          round
          title={false}
          paragraph={{ rows: 2, width: ['100%', '75%'] }}
          loading={fetchingDeductionAmount}
        >
          {deductionAmountData ? (
            <p>
              {t('Please note that an amount of')}{' '}
              <span style={{ fontWeight: 500 }}>
                {' '}
                {
                  deductionAmountData.getCancelTicketDeductionAmount.data
                    ?.deduction_amount
                }
              </span>{' '}
              {t('L.E. is going to be')}{' '}
              <span style={{ fontWeight: 500 }}>{t('deducted')} </span>
              {t(
                "from the ticket's price. Are you sure you want to cancel this ticket?"
              )}
            </p>
          ) : null}
        </Skeleton>
      </Modal>
      <Modal
        title={
          <Space size={'middle'}>
            <ExclamationCircleOutlined
              style={{ fontSize: 22, color: '#FFCC00' }}
            />
            {t('Force Cancel Ticket')}
          </Space>
        }
        visible={forceVisible}
        onCancel={() => {
          setForceVisible(false);
        }}
        footer={[
          <Button key="force_cancel" onClick={() => setForceVisible(false)}>
            {t('Return')}
          </Button>,
        ]}
      >
        <Spin spinning={forceCancelling}>
          <Form
            form={forceCancelForm}
            onFinish={ForceCancel}
            layout={isMobile ? 'horizontal' : 'inline'}
          >
            <Item
              rules={[
                {
                  required: true,
                  message: t('Please, enter the deduction amount!'),
                },
              ]}
              name="deduction_amount"
              label={t('Deduction Amount')}
            >
              <Input placeholder={t('Enter Deduction Amount')} />
            </Item>
            <Item>
              <Button type="danger" htmlType="submit">
                {t('Confirm')}
              </Button>
            </Item>
          </Form>
        </Spin>
      </Modal>

      <Modal
        title={<Space size={'middle'}>{t('Luggage Number')}</Space>}
        visible={luggageModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            {t('Return')}
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              checkPrintTicketLuggage({
                variables: {
                  ticketId: ticketId,
                  luggageNumber: luggageNumber,
                },
              });
            }}
          >
            {t('Select')}
          </Button>,
        ]}
      >
        <Skeleton
          active
          round
          title={false}
          paragraph={{ rows: 2, width: ['100%', '75%'] }}
          loading={false}
        >
          <Item name="luggageNumber" label={t('Select number of luggage')}>
            <Select
              size="large"
              defaultValue="1"
              onChange={(value) => setLuggageNumer(value)}
            >
              <Option key={1} value={1}>
                1
              </Option>
              <Option key={2} value={2}>
                2
              </Option>
            </Select>
          </Item>
        </Skeleton>
      </Modal>
      <Modal
        title={<Space size={'middle'}>Print Fees</Space>}
        visible={checkPrintModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            {t('Return')}
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              window.open('/tickets/view/' + ticketsToPrint.join('&'));
              setCheckPrintModalVisible(false);
            }}
          >
            {t('Confirm')}
          </Button>,
        ]}
      >
        <Skeleton
          active
          round
          title={false}
          paragraph={{ rows: 2, width: ['100%', '75%'] }}
          loading={false}
        >
          {printFees ? (
            <p>
              Please note that printing the ticket will cost{' '}
              {printFees.checkPrintTicketFees.printFees} L.E., confirm to
              continue...
            </p>
          ) : null}
        </Skeleton>
      </Modal>
      {data && ticketInfo && (
        <ReplaceModal
          visible={showReplace}
          setVisible={setShowReplace}
          ticket={ticketInfo}
          remainingTickets={1}
        ></ReplaceModal>
      )}
    </TicketsListContainer>
  );
};

export default TicketsList;
