import React from 'react';
import { Table, Divider } from 'antd';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import { GET_TICKET_DATA } from 'services';
import { ValidateUser } from '../../utilities';
import { useTranslation } from 'react-i18next';

const TicketsList = (props) => {
  ValidateUser();
  const [t, i18n] = useTranslation('translation');
  const nameLang = i18n.language;

  const { data, loading } = useQuery(GET_TICKET_DATA, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      id: props.id,
    },
  });
  const expandedColumns = [
    {
      title: t('Seat Number'),
      dataIndex: 'seat_number',
    },
    {
      title: t('no_cancel'),
      dataIndex: 'no_cancel',
      render: (no_cancel) => {
        return no_cancel ? t('no_cancel') : '';
      },
    },
    {
      title: t('Seat Class'),
      dataIndex: ['seat_type', nameLang === 'en' ? 'name_en' : 'name_ar'],
    },
    {
      title: t('Trip Code'),
      dataIndex: ['trip', 'ref_code'],
    },
    {
      title: t('Trip Date'),
      dataIndex: ['trip', 'date'],
    },
    {
      title: t('From Location'),
      dataIndex: ['from_location', nameLang === 'en' ? 'name_en' : 'name_ar'],
    },
    {
      title: t('To Location'),
      dataIndex: ['to_location', nameLang === 'en' ? 'name_en' : 'name_ar'],
    },
    {
      title: t('Trip Execute'),
      dataIndex: ['from_date'],
    },
    {
      title: t('Departure Time'),
      dataIndex: ['from_time'],
    },
    {
      title: t('Modified_1'),
      render: (ticket) => {
        if (ticket?.updated_to !== null)
          return (
            <Link
              to={`/ticket/${ticket?.updated_to?.id}`}
              onClick={() => window.scrollTo(0, 0)}
            >
              {ticket?.updated_to?.id}
            </Link>
          );

        return ticket?.new_tickets?.map((newTicket, index) => (
          <>
            <Divider type="vertical" />
            <Link
              to={`/ticket/${newTicket.id}`}
              onClick={() => window.scrollTo(0, 0)}
            >
              {newTicket?.id}
            </Link>
          </>
          // <p key={index}>{newTicket.id}</p>
        ));
      },
    },
    {
      title: t('Shift ID'),
      dataIndex: ['station_shift', 'id'],
    },
    {
      title: t('Source'),
      dataIndex: ['order', 'source'],
    },
  ];

  return (
    <>
      <Table
        bordered
        loading={loading}
        columns={expandedColumns}
        dataSource={data !== undefined && [data?.getticketdata]}
        pagination={false}
        rowKey={(record) => record.id}
      />
    </>
  );
};

export default TicketsList;
