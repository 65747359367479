/**Component that displays order summary details and tickets booked for this order
 * with action buttons for ticket to cancel, edit or view ticket
 */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Card,
  Spin,
  Row,
  Col,
  Collapse,
  Button,
  Modal,
  Checkbox,
  Space,
} from 'antd';
import {
  FETCH_ORDER_DETAILS,
  GET_CANCEL_DEDUCTION_AMOUNT,
  CANCEL_TICKET,
  CheckEditTicket,
  CheckBulkEditTickets,
  GET_CANCEL_MULTI_TICKETS_DEDUCTION_AMOUNT,
  CANCEL_MULTI_TICKETS,
} from 'services';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { ListHeader, PrimaryTitle, Can } from 'components';
import { notify } from 'utilities';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const { Panel } = Collapse;

const OrderDetails = ({
  match: {
    params: { id: orderId },
  },
}) => {
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [bulkVisible, setBulkVisible] = useState(false);
  const [ticketId, setTicketId] = useState(0);
  const [ticketIdParams, setTicketParams] = useState(null);
  const [cancelledTickets, setCancelledTickets] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [t, i18n] = useTranslation('translation');
  const nameLang = i18n.language;
  const rejectCancelMethods = ['Wallet', 'Card', 'Link', 'Orange'];
  const [
    getDeductionAmount,
    { data: deductionAmountData, loading: fetchingDeductionAmount },
  ] = useLazyQuery(GET_CANCEL_DEDUCTION_AMOUNT, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res.getCancelTicketDeductionAmount.status) {
        setVisible(true);
      } else {
        notify(
          'error',
          nameLang === 'en'
            ? res.getCancelTicketDeductionAmount.message
            : res.getCancelTicketDeductionAmount.message_ar
        );
      }
    },
  });

  const [getEditTicketDeductionAmount] = useLazyQuery(CheckEditTicket, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res.getEditTicketDeductionAmount.status && ticketIdParams) {
        history.push(`/ticketing?ticketId=${ticketIdParams}`);
      } else {
        notify(
          'error',
          nameLang === 'en'
            ? res.getEditTicketDeductionAmount.message
            : t('edit_ticket')
        );
      }
    },
  });
  const [checkEditTicket] = useLazyQuery(CheckBulkEditTickets, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res.checkEditTicket.status && selectedRows) {
        history.push(`/ticketing?ticketId=${selectedRows.flat().join(',')}`);
      } else {
        notify('error', res.checkEditTicket.message);
      }
    },
  });
  const handleBulkEdit = () => {
    if (selectedRows.length > 0) {
      checkEditTicket({
        variables: {
          ticketId: [...new Set(selectedRows.flat())],
        },
      });
    } else {
      notify('error', 'Select Tickets to Edit...');
    }
  };

  const [cancelTiket, { loading: refunding }] = useMutation(CANCEL_TICKET);
  const [cancelMultiTickets, { loading: multiLoading }] = useMutation(
    CANCEL_MULTI_TICKETS
  );
  const [
    getMultiTicketsDeductionAmount,
    { data: deductionMultiAmountData, loading: fetchingMultiDeductionAmount },
  ] = useLazyQuery(GET_CANCEL_MULTI_TICKETS_DEDUCTION_AMOUNT, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res.getCancelMultiTicketsDeductionAmount.status) {
        setBulkVisible(true);
      } else {
        notify('error', res.getCancelMultiTicketsDeductionAmount.message);
      }
    },
  });

  const handleCancelMultiTickets = () => {
    if (cancelledTickets.length > 0) {
      let tickets = [...new Set(cancelledTickets)];

      cancelMultiTickets({
        variables: {
          id: tickets,
        },
        refetchQueries: () => [
          {
            query: FETCH_ORDER_DETAILS,
            variables: {
              orderId: orderId,
            },
          },
        ],
      })
        .then((response) => {
          const {
            data: {
              adminCancelMultiTickets: { message, status },
            },
          } = response;
          const notificationType = status ? 'success' : 'error';
          notify(notificationType, message);
          setCancelledTickets([]);
          setBulkVisible(false);
        })
        .catch((err) => {
          if (err['graphQLErrors'][0]?.extensions) {
            const {
              extensions: { validation },
              message,
            } = err['graphQLErrors'][0];
            if (validation) {
              for (let error in validation) {
                notify('error', validation[error][0]);
              }
              return;
            }
            notify('error', message);
          }
        });
    } else notify('error', 'Select valid tickets to cancel');
  };

  const { data, loading } = useQuery(FETCH_ORDER_DETAILS, {
    variables: {
      orderId: orderId,
    },
  });

  if (loading) return <Spin />;

  const {
    order: {
      customer: { name: customerName, phone: customerPhone },
      order_number: orderNumber,
      price: totalPrice,
      payment_method: paymentMethod,
      created_at: purchaseDate,
      kiosk_number: kioskNumber,
      payment_url: PaymentLink,
      status: OrderStatus,
      tickets,
    },
  } = data || {
    // default value in case order is undefined.
    order: {
      customer: {
        name: undefined,
        phone: undefined,
      },
      tickets: [],
    },
  };

  const showModal = (ticketId, bulk) => {
    if (!bulk) {
      setTicketId(ticketId);
      getDeductionAmount({
        variables: {
          id: ticketId,
        },
      });
    } else {
      if (cancelledTickets.length <= 0) {
        notify('error', 'Select valid tickets to cancel');
        return;
      }
      getMultiTicketsDeductionAmount({
        variables: {
          id: cancelledTickets,
        },
      });
    }
  };

  /**Submit cancel ticket request */
  const handleOk = (e) => {
    cancelTiket({
      variables: {
        id: ticketId,
        deduction_amount:
          deductionAmountData.getCancelTicketDeductionAmount.data
            .deduction_amount,
      },
      refetchQueries: () => [
        {
          query: FETCH_ORDER_DETAILS,
          variables: {
            orderId: orderId,
          },
        },
      ],
    })
      .then((response) => {
        const {
          data: {
            adminCancelTicket: { message, status },
          },
        } = response;
        const notificationType = status ? 'success' : 'error';
        notify(notificationType, message);
      })
      .catch((err) => {
        const {
          extensions: { validation },
          message,
        } = err['graphQLErrors'][0];

        if (validation) {
          for (let error in validation) {
            notify('error', validation[error][0]);
          }
        } else {
          notify('error', message);
        }
      });
    setVisible(false);
  };

  const handleCancel = (e) => {
    setVisible(false);
    setBulkVisible(false);
  };

  const handleCheckbox = (ticket, e) => {
    if (selectedRows.length === 0) setSelectedRows([ticket.id]);
    else {
      if (selectedRows.includes(ticket.id)) {
        selectedRows.splice(selectedRows.indexOf(ticket.id), 1);
      } else setSelectedRows([...selectedRows, ticket.id]);
    }

    if (cancelledTickets.includes(ticket.id)) {
      cancelledTickets.splice(cancelledTickets.indexOf(ticket.id), 1);
    } else {
      const todayDate = new Date();
      const tripDate = new Date(
        [ticket?.trip.date, ticket?.trip.time].join(' ')
      );
      if (
        ticket.status !== 'Cancelled' ||
        ticket.status !== 'Modified' ||
        todayDate <= tripDate
      )
        setCancelledTickets([...cancelledTickets, ticket.id]);
    }
  };

  return (
    <>
      <ListHeader>
        <PrimaryTitle>{t('Order Summary')}</PrimaryTitle>
        <Space size="middle">
          <Can
            perform="Bulk_Modify"
            yes={
              !rejectCancelMethods.includes(paymentMethod) && (
                <Button type="primary" onClick={() => handleBulkEdit()}>
                  {t('Bulk Edit')}
                </Button>
              )
            }
          />
          <Can
            perform="CANCEL_TICKET"
            yes={
              !rejectCancelMethods.includes(paymentMethod) && (
                <Button
                  danger
                  type="primary"
                  onClick={() => {
                    showModal(ticketId, true);
                  }}
                >
                  {t('Cancel Tickets')}
                </Button>
              )
            }
          />
        </Space>
      </ListHeader>
      <Row gutter={16}>
        <Col span={12}>
          <Card title={t('Customer Info')}>
            <dl>
              <dt>{t('Customer Name')}</dt>
              <dd>{customerName}</dd>

              <dt>{t('Customer Phon')}</dt>
              <dd>{customerPhone}</dd>
            </dl>
          </Card>
        </Col>
        <Col span={12}>
          <Card title={t('Order Info')}>
            <dl>
              <dt>{t('Order Number')}</dt>
              <dd>{orderNumber}</dd>

              <dt>{t('Purchase Date')}</dt>
              <dd>{purchaseDate}</dd>

              <dt>{t('Payment Method')}</dt>
              <dd>{t(paymentMethod)}</dd>

              {kioskNumber && paymentMethod === 'Aman' && (
                <>
                  <dt>{t('Aman Code')}</dt>
                  <dd>{kioskNumber}</dd>
                </>
              )}

              {kioskNumber && paymentMethod === 'Fawry' && (
                <>
                  <dt>{t('Fawry Code')}</dt>
                  <dd>{kioskNumber}</dd>
                </>
              )}
              {PaymentLink && paymentMethod === 'Link' && (
                <>
                  <dt>{t('Payment Link')}</dt>
                  {OrderStatus === 'Pending' ? (
                    <>
                      <CopyToClipboard
                        text={PaymentLink}
                        onCopy={() =>
                          notify('success', t('Copied Successfully'))
                        }
                      >
                        <button>{t('Copy')}</button>
                      </CopyToClipboard>
                      <dd>{PaymentLink}</dd>
                    </>
                  ) : (
                    ''
                  )}
                </>
              )}
              <dt>{t('Total Price')}</dt>
              <dd>{totalPrice}</dd>
            </dl>
          </Card>
        </Col>
      </Row>
      {tickets.length > 0 && (
        <Collapse>
          {tickets.map((ticket, ticketIdx) => {
            const {
              id: ticketId,
              code: ticketCode,
              seat_number: seatNumber,
              seat_type: { name_en: seatTypeName },
              price: ticketPrice,
              status,
              from_time,
              from_date,
              from_location: {
                name_en: fromLocation,
                city: { name_en: fromCity },
                name_ar: fromLocation_ar,
                city: { name_ar: fromCity_ar },
              },
              to_location: {
                name_en: toLocation,
                city: { name_en: toCity },
                name_ar: toLocation_ar,
                city: { name_ar: toCity_ar },
              },
            } = ticket;
            return (
              <Panel
                key={ticketId}
                header={`${
                  ticket.trip?.ref_code ? ticket.trip.ref_code : 'Open round'
                } - ${t(status)}`}
                extra={
                  <>
                    {status !== 'Paid' ? null : (
                      <a
                        target="_blank"
                        href={`/tickets/view/${ticketId}`}
                        style={{ marginRight: '30px' }}
                      >
                        {t('View Ticket')}
                      </a>
                    )}
                    {status === 'Paid' || status === 'Pending' ? (
                      <>
                        {!rejectCancelMethods.includes(paymentMethod) && (
                          <Button
                            type="link"
                            onClick={() => {
                              setTicketParams(ticketId);
                              getEditTicketDeductionAmount({
                                variables: {
                                  ticketId,
                                },
                              });
                            }}
                          >
                            {t('Edit Ticket')}
                          </Button>
                        )}
                        <Can
                          perform="CANCEL_TICKET"
                          yes={
                            !rejectCancelMethods.includes(paymentMethod) && (
                              <Button
                                style={{
                                  marginLeft: '30px',
                                  color: 'white',
                                  backgroundColor: 'red',
                                }}
                                onClick={() => {
                                  showModal(ticketId, false);
                                }}
                              >
                                {t('Cancel Ticket')}
                              </Button>
                            )
                          }
                        />
                        <Checkbox
                          style={{ marginLeft: '30px' }}
                          onChange={(e) => {
                            handleCheckbox(ticket, e);
                          }}
                        ></Checkbox>
                      </>
                    ) : null}
                  </>
                }
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Card title={t('Trip Info')}>
                      <dl>
                        {ticket.trip?.ref_code ? (
                          <>
                            <dt>{t('Reference Code')}</dt>
                            <dd>{ticket.trip.ref_code}</dd>
                          </>
                        ) : (
                          ''
                        )}

                        {from_date ? (
                          <>
                            <dt>{t('Datetime')}</dt>
                            <dd>{`${from_date} ${from_time}`}</dd>
                          </>
                        ) : (
                          ''
                        )}

                        <dt>{t('From')}</dt>
                        <dd>{`${nameLang === 'en' ? fromCity : fromCity_ar} - ${
                          nameLang === 'en' ? fromLocation : fromLocation_ar
                        }`}</dd>
                        <dt>{t('To')}</dt>
                        <dd>{`${nameLang === 'en' ? toCity : toCity_ar} - ${
                          nameLang === 'en' ? toLocation : toLocation_ar
                        }`}</dd>
                      </dl>
                      <dl></dl>
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card title={t('Ticket Info')} className="ticket-wrapper">
                      <dl>
                        <dt>{t('Ticket ID')}</dt>
                        <dd>{ticketId}</dd>

                        <dt>{t('Ticket Number')}</dt>
                        <dd>{ticketCode}</dd>
                      </dl>
                    </Card>
                    <Card title={t('Seats Info')}>
                      <dl>
                        <dt>{t('Seat Type')}</dt>
                        <dd>{t(seatTypeName)}</dd>

                        <dt>{t('Seat Number')}</dt>
                        <dd>{seatNumber}</dd>

                        <dt>{t('Price')}</dt>
                        <dd>{ticketPrice}</dd>
                      </dl>
                    </Card>
                  </Col>
                </Row>
              </Panel>
            );
          })}
        </Collapse>
      )}

      <Modal
        title={t('Confirm Cancellation')}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Spin spinning={fetchingDeductionAmount}>
          {deductionAmountData &&
          deductionAmountData.getCancelTicketDeductionAmount &&
          deductionAmountData.getCancelTicketDeductionAmount.data ? (
            <p>
              {t('The deduction amount is')}{' '}
              {
                deductionAmountData.getCancelTicketDeductionAmount.data
                  .deduction_amount
              }
              {t('. Are you sure you want to cancel this ticket?')}
            </p>
          ) : null}
        </Spin>
      </Modal>
      <Modal
        title={t('Confirm Cancellation')}
        visible={bulkVisible}
        onOk={handleCancelMultiTickets}
        onCancel={handleCancel}
      >
        <Spin spinning={fetchingMultiDeductionAmount || multiLoading}>
          {deductionMultiAmountData &&
          deductionMultiAmountData.getCancelMultiTicketsDeductionAmount &&
          deductionMultiAmountData.getCancelMultiTicketsDeductionAmount.data ? (
            <p>
              {t('The deduction amount is')}{' '}
              {
                deductionMultiAmountData.getCancelMultiTicketsDeductionAmount
                  .data.deduction_amount
              }
              . {t('Are you sure you want to cancel these tickets?')}
            </p>
          ) : null}
        </Spin>
      </Modal>
    </>
  );
};

export default OrderDetails;
